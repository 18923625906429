<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4" style="font-size: 16pt; font-weight: bold">
        UPEI Academic Integrity Statement
      </p>

      <p class="mb-4" style="font-size: 11pt">
        The following excerpts have been copied directly from the University of Prince Edward Island
        Academic Calendar 2020-21 under 20. Academic Integrity. Retrieved from:
        <a
          href="https://calendar.upei.ca/current/chapter/undergraduate-and-professional-programs-academic-regulations/#AR#20"
          target="_blank"
          >https://calendar.upei.ca/current/chapter/undergraduate-and-professional-programs-academic-regulations/#AR#20</a
        >
      </p>

      <p class="mb-4 pl-6" style="font-size: 11pt; font-style: italic; text-align: justify">
        “As a community of scholars, the University of Prince Edward Island is committed to the
        principle of academic integrity among all its participants. Each student is responsible for
        their conduct which affects the University Community and is expected to conduct themselves
        in an ethical manner in their academic work. Academic Dishonesty will not be tolerated and,
        within the constraints of this Regulation and Academic Regulation 12 (Academic Appeals), the
        University supports instructors in their efforts to deal effectively with cases as they may
        arise from time to time.”
      </p>

      <p class="mb-2" style="font-size: 11pt; text-align: justify">
        Cheating can be defined as attempting to secure a grade for yourself or others by unethical
        means. Some examples of cheating include:
      </p>

      <ul class="mb-4" style="font-size: 11pt">
        <li>Impersonating someone during a test or exam</li>
        <li>
          Consulting or requesting online solutions to assignment or exam questions via services
          like Chegg, CourseHero, etc.
        </li>
        <li>
          Obtaining, copying, and/or sharing a copy of a test or exam before it is administered
        </li>
        <li>
          Unauthorized collaboration between students when individual work is required (e.g. take
          home exams)
        </li>
      </ul>

      <p class="mb-5" style="font-size: 11pt; text-align: justify">
        As a student understanding the university's expectations of academic integrity, please check
        the box below. If you do not agree with the expectations listed above, your exam will not be
        graded.
      </p>

      <div v-for="option in options" :key="option.value">
        <v-checkbox
          v-model="inputs.input1"
          :disabled="!allowEditing"
          class="d-inline-block"
          :value="option.value"
        />
        <stemble-latex :content="option.expression" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'IntegrityAgreement',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          expression: 'I agree to abide by the Academic Integrity Expectations of UPEI',
          value: 'agreed',
        },
      ],
    };
  },
};
</script>
